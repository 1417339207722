<template>
    <div class="h-100 mt-8 d-flex flex-column justify-content-between">
        <section class="container-form d-flex flex-md-row flex-column-reverse justify-content-between">
            <div class="text-form mt-4 mt-md-0">
                <h1 class="mb-5 text-center text-md-left">Oops...</h1>
                <p class="paragraph-form">
                    Parece que a página que você tentou acessar não existe.
                </p>

                <v-btn :style="styleButton"
                       elevation="0"
                       class="btn w-100 mt-5"
                       to="/">Voltar ao início</v-btn>
            </div>
            <hr class="d-md-hidden d-inline" />
            <div id="container-img-splash" class="d-flex flex-column align-items-center my-md-0 mb-4 mt-md-8">
                <v-img contain class="image-splash" :src="gears"></v-img>
            </div>
        </section>

    </div>
</template>

<script>
    import gears from '../assets/images-splash/gears.png';

    export default {
        name: 'NotFound',

        components: {},

        data() {
            return ({
                gears,
                text: '404',
                styleButton: {
                    backgroundColor: this.$colors.news,
                    color: this.$colors.grays.white,
                },
            })
        },
    }
</script>

<style>
    @keyframes float {
        0% {
            transform: translateY( 0px);
        }

        50% {
            transform: translateY(-20px);
        }

        100% {
            transform: translateY( 0px);
        }
    }

    .image-splash {
        width: max(800px, 10vw);
        height: auto;
        transform: translateY(-10px);
        animation: float 6s ease-in-out infinite;
    }

    .caption-splash {
        margin: 0;
        font-size: 56px;
        line-height: 60px;
    }

    #container-img-splash {
        width: 25vw;
    }

    @media (max-width: 960px) {
        section {
            margin: 10px 10px 30px 10px;
        }

        .image-splash {
            width: 50vw;
        }

        #container-img-splash {
            width: 50vw;
        }

        .caption-splash {
            font-size: 32px;
            line-height: 36px;
        }
    }
</style>